import { Logger } from 'kersplunk';

export const logger = Logger.create({
  splunkUrl: 'https://http-inputs-coxauto.splunkcloud.com/services/collector/event',
  authToken: 'B58D48D7-FCBC-5B29-8DE8-BCB9554A9F80',
  splunkMeta: {
    index: 'man_app',
    source: 'qna-ui',
    sourcetype: '_json'
  },
  enabled: process.env.NODE_ENV !== 'test'
});
