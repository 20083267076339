import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import { DamageGuideSection } from '../../models/QEQuestion';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PrimaryButton } from '../../components/Buttons';
import { Close } from '@styled-icons/evaicons-solid';
import { InlineIcon } from '@iconify/react';

type Props = { definition: DamageGuideSection; onClose: () => void };
export const DamageModal = (props: Props) => {
  return (
    <Container>
      <TopCloseX data-test-id="top-close-x" onClick={props.onClose}></TopCloseX>
      <HeaderText data-test-id="header-text">{props.definition.header}</HeaderText>
      <SubSectionText data-test-id="subheader-text">{props.definition.subHeader}</SubSectionText>
      {props.definition.subSections.map((section, index) => {
        return (
          <React.Fragment key={index}>
            <SubHeaderText data-test-id={`subsection-header-${index}`}>{section.header}</SubHeaderText>
            {/* dangeroulySetInnerHTML is only used to bold selective text within subheader without the need to pull in an additional library */}
            <SubSectionText
              dangerouslySetInnerHTML={{ __html: calculateSubheader(section.subHeader) }}
              data-test-id={`subsection-subheader-${index}`}
            />
            <Carousel autoPlay={false} showThumbs={false}>
              {section.images?.map((image, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '200px',
                    justifyContent: 'center',
                    backgroundColor: 'black'
                  }}
                >
                  <img
                    data-test-id={`subsection-${index}-image-${i}`}
                    src={image}
                    key={i}
                    style={{
                      objectFit: 'contain'
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </React.Fragment>
        );
      })}
      <CloseButton data-test-id="bottom-close-btn" onClick={props.onClose}>
        CLOSE <InlineX icon="vaadin:close" />
      </CloseButton>
    </Container>
  );
};

// This functions replaces ** with <b> or </b> in a given string
export const calculateSubheader = (text: string) => {
  let resultText = text;

  // depending on whether or not we are adding <b> or </b>,
  // we need to add additional characters to the final output
  // replacing ** with <b> requires 1 additional character
  // replacing ** with </b> requires 2 additional characters
  // later, we will use this const
  // to keep up with amt of characters that have been added to the string
  let additionalCharCount = 0;

  // const doubleAsteriskOccurences is an array of special arrays that look like this:
  // [ '**', index: 16, input: 'A minor scratch **smaller than a credit card** not **larger than a credit card**.', groups: undefined ]
  // these arrays are special because they have some extra properties on them
  // e.g. index, input, groups
  // index tells us where the match occurred in the string
  const doubleAsteriskOccurences = Array.from(text.matchAll(/\*\*/g));

  doubleAsteriskOccurences.forEach((match, index) => {
    let tag = '<b>';
    let addedCharCount = 1;

    // every other occurence of '**'
    // needs to be replaced with a closing tag </b>
    if (index % 2 === 1) {
      tag = '</b>';
      // the closing tag has one additional character to account for
      addedCharCount++;
    }

    // typescript requires this check but index will never be undefined
    // https://github.com/microsoft/TypeScript/issues/36788#issuecomment-633178914
    if (match.index === undefined) {
      return resultText;
    }

    // if we've added characters to the string in previous iterations
    // the index of the match has changed
    const updatedIndex = match.index + additionalCharCount;

    const stringUpUntilAsterisks = resultText.substring(0, updatedIndex);
    const stringAfterAsterisks = text.substring(match.index + 2);
    resultText = stringUpUntilAsterisks + tag + stringAfterAsterisks;

    additionalCharCount += addedCharCount;
  });
  return resultText;
};

const InlineX = styled(InlineIcon)`
  margin-left: 5px;
`;

const Container = styled.div`
  position: absolute;
  left: -7px;
  width: calc(100% - 6px);
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 10px;
  z-index: 100;
  background-color: white;
  border: 1px;
  padding: 10px 10px 20px 10px;
`;

const CloseButton = styled(PrimaryButton)`
  width: 100%;
  margin: 20px 0px 0px 0px;
  font-weight: 700;
`;

const TopCloseX = styled(Close)`
  color: grey;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
`;

const HeaderText = styled.h1`
  font-family: Poppins;
  color: ${({ theme }) => theme.color.headerTextColor};
  margin: 5px;
  font-size: 20px;
`;

const SubHeaderText = styled.h3`
  margin: 10px;
  font-size: 15px;
`;

const SubSectionText = styled.p`
  margin: 10px;
`;
