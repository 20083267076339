import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logger } from '../../logger';

// Configure axios to log requests/responses
const requestLogDetails = (config: AxiosRequestConfig) => ({
  url: config.url,
  baseUrl: config.baseURL,
  params: config.params,
  method: config.method,
  data: config.data,
  headers: config.headers
});

const responseLogDetails = (response: AxiosResponse) => ({
  status: response.status,
  statusText: response.statusText,
  headers: response.headers,
  data: response.data,
  duration: new Date().valueOf() - (response.config as any).metadata.startTime.valueOf()
});

export const logRequestAndResponseDetails = (client: AxiosInstance) => {
  client.interceptors.request.use(config => ({
    ...config,
    metadata: { startTime: new Date() } // To track request duration
  }));

  client.interceptors.response.use(
    response => {
      logger.info('httpRequest', {
        request: requestLogDetails(response.config),
        response: responseLogDetails(response)
      });
      return response;
    },
    error => {
      logger.error('httpRequest', {
        request: error.config && requestLogDetails(error.config),
        response: error.response && responseLogDetails(error.response),
        message: typeof error === 'string' ? error : error.message
      });
      throw error;
    }
  );
};
