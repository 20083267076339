import { QuestionState, AppState, AnswerDetails, AnswerMetaData } from '../models/AppState';
import { isSourceLocked } from './mappingHelpers';
import { WorldState } from './computeWorldState';

const updateNote = (answerDetails: AnswerDetails) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { notes, ...answerWithoutNotes } = answerDetails;
  const trimmedNotes = notes?.trim();
  return trimmedNotes ? { ...answerDetails, notes: trimmedNotes } : answerWithoutNotes;
};

const trimNotes = (questionState: QuestionState) => ({
  ...questionState,
  answers: questionState.answers.map(updateNote)
});

export const createAppState = ({
  prompting,
  questionnaireHref,
  worldState
}: {
  prompting: boolean;
  questionnaireHref: string;
  worldState: WorldState;
}): AppState => {
  const metaData = worldState.filteredQuestionResponses.reduce<AnswerMetaData[]>((acc, response) => {
    const questionFromQuestionnaire = worldState.questionnaire.find(q => q.guid === response.guid);
    const answersWithMetadata = questionFromQuestionnaire?.answers.reduce<AnswerMetaData[]>((accu, ans) => {
      return ans.metaData && response.answers.find(a => a.value === ans.value)
        ? [...accu, { guid: questionFromQuestionnaire.guid, value: ans.value, metaData: ans.metaData }]
        : accu;
    }, []);
    if (answersWithMetadata?.length) {
      return [...acc, ...answersWithMetadata];
    } else {
      return acc;
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const allQuestionsHaveAnswers = worldState.questionnaire.every(question => {
    const answers = worldState.filteredQuestionResponses.find(response => response.guid === question.guid)?.answers;
    return (
      !worldState.isQuestionRequired(question) ||
      (answers?.length && answers.every(answer => question.answers.some(a => a.value === answer.value)))
    );
  });

  const announcementsAgree = worldState.calculatedAnnouncements.every(
    s => s.value === worldState.announcements.some(a => a.code === s.code)
  );
  const sourceLockedAnnouncementsThatWouldBeRemoved = worldState.initialAnnouncements.filter(
    ia =>
      isSourceLocked(worldState.announcerSource, ia.source) && !worldState.announcements.some(a => a.code === ia.code)
  );
  const sourceLockedAnnouncementsHaveBeenRemoved = !sourceLockedAnnouncementsThatWouldBeRemoved.length;
  return {
    valid: allQuestionsHaveAnswers && announcementsAgree && sourceLockedAnnouncementsHaveBeenRemoved,
    prompting,
    // We are mapping on the deprecated `values` array for backwards compatibility
    questionResponses: [...worldState.filteredQuestionResponses, ...worldState.preservedResponses].map(r => ({
      ...trimNotes(r),
      values: r.answers.map(a => a.value)
    })),
    announcements: worldState.announcements,
    totalQuestions:
      worldState.questionnaire.filter(q => worldState.isQuestionRequired(q)).length +
      worldState.preservedResponses.length,
    questionnaireHref,
    metaData
  };
};
