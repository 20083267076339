import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { AnswerLabel } from './AnswerLabel';
import { DamagesRecorded } from './DamagesRecorded';
import { PlusOutline } from '@styled-icons/evaicons-outline/PlusOutline';
import { MinusOutline } from '@styled-icons/evaicons-outline/MinusOutline';
import { QEAnswer, QEQuestion } from '../../models/QEQuestion';
import { AlertMessageDetails } from '../../models/AppState';

type Props = {
  label: string;
  value: number;
  maxQuantity?: number | null;
  disabled?: boolean;
  onChange: (newQuantity: number) => boolean;
  helpText?: string;
  photoCaptured?: boolean;
  question: QEQuestion;
  answer: QEAnswer;
  sendAlertMessage?: (alertMessages: AlertMessageDetails) => void;
};

export const QuantityPicker = ({
  label,
  value,
  maxQuantity,
  disabled,
  onChange,
  helpText,
  photoCaptured,
  question,
  answer,
  sendAlertMessage
}: Props) => {
  const [className, setClassName] = useState(value > 0 ? 'active' : 'inactive');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setClassName(value > 0 ? 'active' : 'inactive');
  }, [value]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleOnFocus = () => {
    setClassName('active');
    setIsEditing(true);
  };

  const handleOnBlur = () => {
    setIsEditing(false);
    if (value === 0) {
      setClassName('inactive');
    }
  };

  const handleonInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue === '') {
      onChange(0);
      return;
    }

    const newValue = parseInt(inputValue, 10);

    if (newValue >= 0 && newValue <= (maxQuantity ?? 999)) {
      onChange(newValue);
    } else {
      setError(true);
    }
  };

  return (
    <PickerContainer className={className}>
      <AnswerLabel value={label} helpText={helpText} photoCaptured={photoCaptured} chooseQuantity />
      {photoCaptured && value && value > 0 ? (
        <DamagesRecorded count={value} question={question} answer={answer} sendAlertMessage={sendAlertMessage} />
      ) : null}
      {!photoCaptured && (
        <>
          <ButtonContainer>
            <IncrementButton
              className={className}
              disabled={disabled}
              data-test-id="down-arrow"
              onClick={() => {
                const newValue = value > 0 ? value - 1 : value;
                if (newValue === value) {
                  return;
                }
                const prompting = onChange(newValue);
                if (prompting) {
                  return;
                }
                setClassName(newValue > 0 ? 'active' : 'inactive');
              }}
            >
              <label htmlFor="down" style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
                down
              </label>
              <MinusIcon className={className} />
            </IncrementButton>
            <Line className={className} />
            <NumberContainer>
              <QuantityPickerInput
                className={`${className} quantity-picker-input`}
                data-test-id="quantity-picker-input"
                type="number"
                value={value === 0 && isEditing ? '' : value}
                step={1}
                min={0}
                max={maxQuantity ?? 999}
                onInput={handleonInput}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
              />
            </NumberContainer>
            <Line className={className} />
            <IncrementButton
              className={className}
              disabled={disabled}
              data-test-id="up-arrow"
              onClick={() => {
                if (maxQuantity === 0) {
                  return;
                }
                const newValue = maxQuantity && value >= maxQuantity ? value : value + 1;
                if (newValue === value) {
                  return;
                }
                const prompting = onChange(newValue);
                if (prompting) {
                  return;
                }
                setClassName(newValue > 0 ? 'active' : 'inactive');
              }}
            >
              <label htmlFor="up" style={{ width: '1px', height: '1px', overflow: 'hidden' }}>
                up
              </label>
              <PlusIcon className={className} />
            </IncrementButton>
          </ButtonContainer>
          {error && <ErrorText data-test-id="error-text">Value cannot be greater than {maxQuantity ?? 999}</ErrorText>}
        </>
      )}
    </PickerContainer>
  );
};

const ErrorText = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  color: red;
  justify-content: center;
  font-weight: bold;
  padding: ${({ theme }) => theme.space.sm};
`;

const QuantityPickerInput = styled.input`
  &.quantity-picker-input {
    display: flex;
    width: 100%;
    border: none;
    font-weight: 700;
    font-size: 18px;
    font-family: Poppins;
    text-align: center;
    padding: 0;
  }

  &.inactive {
    color: ${({ theme }) => theme.color.activeButtonBackground};
    background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  }
  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PickerContainer = styled.div`
  margin: 0; // Fixes safari automatic margin on buttons
  padding-top: ${({ theme }) => theme.space.xs};
  padding-bottom: ${({ theme }) => theme.space.xs};
  border-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; // Needed for Safari on iOS 14.0 bug
  font-size: ${({ theme }) => theme.typography.size.md};
  color: ${({ theme }) => theme.color.inactiveButtonText};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  border-color: ${({ theme }) => theme.color.inactiveButtonBorder};
  border-radius: 0;
  flex-direction: column; // Needed for Safari on iOS 14.0 bug
  flex-wrap: wrap; // Needed for Safari on iOS 14.0 bug
  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
    border-color: ${({ theme }) => theme.color.activeButtonBorder};
  }
`;

const ButtonContainer = styled.div`
  padding-top: ${({ theme }) => theme.space.xs};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const NumberContainer = styled.div`
  justify-content: center;
  text-align: center;
  width: 40px;
`;

const PlusIcon = styled(PlusOutline)`
  color: ${({ theme }) => theme.color.qtyPickerIconText};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  height: 22px;
  width: 22px;

  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;
const MinusIcon = styled(MinusOutline)`
  color: ${({ theme }) => theme.color.disabledQtyPickerIconText};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  height: 22px;
  width: 22px;

  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;

const IncrementButton = styled.button`
  border-radius: ${({ theme }) => theme.radius.xl};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.md};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  border: 1px solid ${({ theme }) => theme.color.enabledButtonBorder};
  color: ${({ theme }) => theme.color.secondaryButtonText};
  line-height: 44px;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.space.lg};
  padding-right: ${({ theme }) => theme.space.lg};
  padding-top: ${({ theme }) => theme.space.xs};
  padding-bottom: ${({ theme }) => theme.space.xs};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.disabledButtonBackground};
    border-color: ${({ theme }) => theme.color.disabledButtonBorder}};
    color: ${({ theme }) => theme.color.disabledButtonText};
  }

  &.selected {
    color: ${({ theme }) => theme.color.activeButtonText};
    border-color: ${({ theme }) => theme.color.activeButtonBorder};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }

  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
    border-color: ${({ theme }) => theme.color.activeButtonText}};
  }

  &.inactive {
    border-color: ${({ theme }) => theme.color.inactiveIncrementButtonBorder}};
  }
`;

const Line = styled.div`
  background-color: ${({ theme }) => theme.color.inactiveIncrementButtonBorder};
  height: 1px;
  width: 30px;

  &.active {
    background-color: ${({ theme }) => theme.color.activeButtonText};
  }
`;

// TODO: Move this to a shared component folder and make this more reusable with props
export type SVGFill = '#4a4a4a' | string;
export type ChevronProps = {
  marginRight?: number;
  fill?: SVGFill;
  onClick?: () => void;
  disabled?: boolean;
  value?: string;
};

export const ChevronDown = ({ marginRight = 0, fill = '#4a4a4a', onClick }: ChevronProps) => {
  return (
    <svg
      aria-labelledby="chevron-down"
      style={{ marginRight }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...{ onClick }}
    >
      <title>chevron-down</title>
      <path fill={fill} d="M16 5.5l-2-2-6 6-6-6-2 2 8 8 8-8z"></path>
    </svg>
  );
};

export const ChevronUp = ({ marginRight = 0, fill = '#4a4a4a', onClick, disabled }: ChevronProps) => {
  return (
    <svg
      aria-labelledby="chevron-up"
      style={{ marginRight }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...{ onClick, disabled }}
    >
      <title>chevron-up</title>
      <path fill={fill} d="M0 10.5l2 2 6-6 6 6 2-2-8-8-8 8z"></path>
    </svg>
  );
};
