import { ClientAnnouncerSource } from '../models/AppConfiguration';
import { QuestionState, AnnouncementState } from '../models/AppState';
import { QEQuestion, QEAnswer } from '../models/QEQuestion';
import {
  removeResponsesForOptionalQuestions,
  calculateCurrentStateOfAnnouncements,
  isSourceLocked,
  isQuestionRequired
} from './mappingHelpers';

export type WorldState = ReturnType<typeof computeWorldState>;
export type WorldStateProps = {
  questionnaire: QEQuestion[];
  questionResponses: QuestionState[];
  preservedResponses: QuestionState[];
  announcements: AnnouncementState[];
  initialAnnouncements: AnnouncementState[];
  announcerSource: ClientAnnouncerSource;
  tags: string[];
  userType: string[];
};
export const computeWorldState = ({
  questionnaire,
  questionResponses,
  preservedResponses,
  announcements,
  initialAnnouncements,
  announcerSource,
  tags,
  userType
}: WorldStateProps) => {
  const filteredQuestionResponses = removeResponsesForOptionalQuestions(questionResponses, questionnaire);
  const calculatedAnnouncements = calculateCurrentStateOfAnnouncements(
    questionnaire,
    filteredQuestionResponses,
    announcements
  );
  const removedAnnouncements = initialAnnouncements.filter(ann =>
    calculatedAnnouncements.some(ca => ca.code === ann.code && ca.value !== true)
  );
  const removedSourceLockedAnnouncements = removedAnnouncements.filter(a => isSourceLocked(announcerSource, a.source));
  const questionAnswersLookupByGuid = questionnaire.reduce<{ [guid: string]: QEAnswer[] }>((acc, question) => {
    const answerValues = questionResponses.find(qr => qr.guid === question.guid)?.answers.map(ad => ad.value) || [];
    const selectedAnswers: QEAnswer[] = question.answers.filter(answer => answerValues.includes(answer.value));

    return {
      ...acc,
      [question.guid]: selectedAnswers
    };
  }, {});

  const questionRequiredLookupByGuid = questionnaire.reduce<{ [guid: string]: boolean }>(
    (acc, question) => ({
      ...acc,
      [question.guid]: isQuestionRequired(question, filteredQuestionResponses, questionnaire)
    }),
    {}
  );

  const questionIsNestedLookupByGuid = questionnaire.reduce<{ [guid: string]: boolean }>(
    (acc, question) => ({
      ...acc,
      [question.guid]: questionnaire.some(q =>
        q.answers.some(answer => answer.nestedQuestions?.includes(question.guid))
      )
    }),
    {}
  );

  return {
    questionnaire,
    questionResponses,
    preservedResponses,
    announcements,
    initialAnnouncements,
    announcerSource,
    filteredQuestionResponses,
    calculatedAnnouncements,
    removedAnnouncements,
    removedSourceLockedAnnouncements,
    tags,
    userType,
    answersForQuestion: (question: QEQuestion) => questionAnswersLookupByGuid[question.guid],
    isQuestionRequired: (question: QEQuestion) => questionRequiredLookupByGuid[question.guid],
    isNestedQuestion: (question: QEQuestion) => questionIsNestedLookupByGuid[question.guid]
  } as const;
};
