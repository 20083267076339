import React from 'react';
import ReactDOM from 'react-dom';
import { setupAxios } from './helpers/httpRequestHelper';
import { QuestionsScene } from './scenes/QuestionsScene/QuestionsScene';
import { AnnouncementsScene } from './scenes/AnnouncementsScene/AnnouncementsScene';
import { createTheme } from './styles/theme';
import { ThemeProvider } from 'styled-components';
import { logger } from './logger';
import { verifyAppConfiguration } from './helpers/verifyAppConfiguration';
import { BackwardCompatibleAppConfiguration, LegacyQuestionState } from './models/AppConfiguration';
import { logRequestAndResponseDetails } from './api/utils/logRequestAndResponseDetails';
import axios from 'axios';

const isLegacyQuestionState = (state: any): state is LegacyQuestionState => !('answers' in state);

const convertLegacyAppConfig = (config: BackwardCompatibleAppConfiguration) => {
  return {
    ...config,
    initialState: {
      ...config.initialState,
      questionResponses: config.initialState.questionResponses.map(qr =>
        isLegacyQuestionState(qr) ? { ...qr, answers: qr.values.map(value => ({ value })), values: undefined } : qr
      )
    }
  };
};

const renderScene = (sceneName: 'questions' | 'announcements', userConfig: BackwardCompatibleAppConfiguration) => {
  logger.interceptor = log => ({
    ...log,
    cai_app: 'qna-ui', // eslint-disable-line camelcase
    cai_environment: userConfig.environment, // eslint-disable-line camelcase
    meta: {
      location: window.location.href,
      sceneName,
      config: userConfig,
      build: process.env.GIT_SHA?.substr(0, 7) || 'localhost',
      buildTime: process.env.BUILD_TIMESTAMP
    }
  });
  logger.info('app:initialized');
  const Scene = sceneName === 'questions' ? QuestionsScene : AnnouncementsScene;
  const config = convertLegacyAppConfig(userConfig);
  verifyAppConfiguration(config);
  setupAxios(config);
  const rootElement = document.getElementById(config.rootElementId);
  if (!rootElement) {
    logger.error('app:initialize', { error: `Root element not found: ${config.rootElementId}` });
    return;
  }
  ReactDOM.unmountComponentAtNode(rootElement);
  ReactDOM.render(
    <ThemeProvider theme={createTheme(config.colors)}>
      <Scene {...config} />
    </ThemeProvider>,
    rootElement
  );
};

logRequestAndResponseDetails(axios);
export const QuestionsAndAnnouncements = {
  renderQuestions: (userConfig: BackwardCompatibleAppConfiguration) => {
    renderScene('questions', userConfig);
  },
  renderAnnouncements: (userConfig: BackwardCompatibleAppConfiguration) => {
    renderScene('announcements', userConfig);
  }
};

Object.assign(window, { QuestionsAndAnnouncements });
