export type DamageGuides = Record<string, DamageGuideSection>;

export type DamageGuideSection = {
  header: string;
  subHeader: string;
  subSections: DamageGuideSubSection[];
};

export type DamageGuideSubSection = {
  header: string;
  subHeader: string;
  images?: string[];
};

export type Questionaire = {
  items: QEQuestion[];
  href: string;
  count: number;
  autoTag?: boolean;
  damageGuides?: DamageGuides;
  formName?: string;
};
export type QEQuestion = {
  id: string;
  text: string;
  buyerTranslation?: string;
  answers: QEAnswer[];
  defaultAnswers?: string[];
  answerGroups?: string[];
  section: string;
  guid: string;
  type?: 'single' | 'multi';
  optional?: boolean;
  tags?: string[];
  userType?: string[];
  subSection?: string;
  helpText?: string;
  guideImages?: GuideImage[];
};

export type GuideImage = { url: string; tags?: string[] };

type ArtCodeType = {
  itemCode?: number;
  itemSubCode?: number;
  damageCode?: string;
  severityCode?: string;
};

// eslint-disable-next-line no-shadow
export enum TakePhotoType {
  Damage = 'damage'
}

export type MetaDataType = {
  dsi?: {
    dsiIneligible: boolean;
  };
  takePhoto?: {
    type: TakePhotoType;
    title?: string;
    description?: string;
  };
  artCode?: ArtCodeType;
};

export type QEAnswer = {
  value: string;
  connotation: number;
  answerGroups?: string[];
  requiredQuestions?: string[];
  nestedQuestions?: string[];
  announcements?: string[];
  showNotes?: boolean;
  notesLabel?: string;
  chooseQuantity?: boolean;
  tags?: string[];
  userType?: string[];
  maxQuantity?: number | null;
  metaData?: MetaDataType;
  helpText?: string;
};
