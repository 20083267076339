import styled from 'styled-components';
import React from 'react';
import { Camera } from '@styled-icons/fa-solid';

export const MAX_CHARACTERS = 50;

type Props = {
  count: number;
  onClick: () => void;
};

export const TakePhotos = ({ count, onClick }: Props) => {
  return (
    <TakePhotosContainer
      style={{
        borderTop: 'none'
      }}
    >
      <TakePhotsButton onClick={onClick}>
        <CameraIcon />
        Take Required Photos ({count})
      </TakePhotsButton>
    </TakePhotosContainer>
  );
};

const TakePhotosContainer = styled.div`
  margin: 0; // Fixes safari automatic margin on buttons
  flex-grow: 1; // Needed for Safari on iOS 14.0 bug
  flex-wrap: wrap; // Needed for Safari on iOS 14.0 bug
  flex-shrink: 1;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
`;

const CameraIcon = styled(Camera)`
  padding-right: ${({ theme }) => theme.space.sm};
  height: 24px;
  width: 19px;
`;

const TakePhotsButton = styled.button`
  border-radius: ${({ theme }) => theme.radius.xl};
  margin-left: ${({ theme }) => theme.space.lg};
  margin-right: ${({ theme }) => theme.space.lg};
  margin-bottom: ${({ theme }) => theme.space.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.md};
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
  border: 1px solid ${({ theme }) => theme.color.secondaryButtonText};
  color: ${({ theme }) => theme.color.secondaryButtonText};
  line-height: 44px;
  white-space: nowrap;
  padding-left: ${({ theme }) => theme.space.lg};
  padding-right: ${({ theme }) => theme.space.lg};

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.disabledButtonBackground};
    border-color: ${({ theme }) => theme.color.disabledButtonBorder};
    color: ${({ theme }) => theme.color.disabledButtonText};
  }

  &.selected {
    color: ${({ theme }) => theme.color.activeButtonText};
    border-color: ${({ theme }) => theme.color.activeButtonBorder};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;
