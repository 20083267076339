import { calculateVehicleTags } from 'vehicle-tagging-library';

import { fetchVehicleDescription } from '../api/fetchVehicleDesignatedDescription';
import { CatalogItemType, EquipmentGenericsType } from '../models/VehicleDesignatedDescription';
import { Unit } from '../models/Unit';
import { logger } from '../logger';

type TaggingOptionsType = {
  catalogId: string;
  vehicleType: string;
  fuelCategory: string;
  vehicleMake: string;
  installedEquipments: EquipmentGenericsType[];
};

const filterInstalledEquipments = (catalogItem: CatalogItemType) => {
  const generics =
    catalogItem.installedEquipment?.flatMap(equipments => equipments.generics).filter(item => item !== undefined) || [];
  return generics as EquipmentGenericsType[];
};

const getVehicleInformation = async (unit: Unit): Promise<CatalogItemType> => {
  const vehicleDescriptions = await fetchVehicleDescription(unit.vin);
  return vehicleDescriptions.catalogItems[0];
};

const generateTaggingOption = (catalogItem: CatalogItemType): TaggingOptionsType => {
  const options = {
    catalogId: catalogItem.catalogId,
    vehicleType: catalogItem.bodyDescription?.vehicleType || '',
    fuelCategory: catalogItem.powertrain?.engine?.fuelCategory || '',
    vehicleMake: catalogItem.make || '',
    installedEquipments: filterInstalledEquipments(catalogItem)
  };
  return options;
};

export const computeTagsFromDDS = async (unit: Unit) => {
  const vehicleCatalogItem = await getVehicleInformation(unit);
  const taggingOption = generateTaggingOption(vehicleCatalogItem);
  logger.info('computeTagsFromDDS:options', taggingOption);
  const { success, data } = calculateVehicleTags(taggingOption);
  logger.info('computeTagsFromDDS:vehicleTags', data);
  return success ? data.tags : [];
};
