import {
  QuestionAndAnnouncementMappings,
  QuestionAndAnnouncementMapping
} from '../models/QuestionAndAnnouncementMappings';
import { QEQuestion, QEAnswer } from '../models/QEQuestion';

const MAPPINGS: QuestionAndAnnouncementMappings = {
  AN0005: {
    // 4X4 System INOP
    questionGuids: [
      'af624704-b8e0-440d-97d3-df8db3550c08' // Does the vehicle have 4WD or AWD?
    ],
    connotationIsNegative: true
  },
  AN0008: {
    // BioHazard
    questionGuids: [
      '00083fd1-6484-4d20-b5c3-256effe4fc7d' // Does the interior of this vehicle have any odor?
    ],
    connotations: [-3]
  },
  AN0011: {
    // Emission Control Equip Missing/INOP
    questionGuids: [
      'a284d362-f9a0-4a38-bb4c-212b17b0605b' // Does this vehicle have modifications to exhaust emissions?
    ],
    connotations: [-2]
  },
  AN0012: {
    // Engine Problem
    questionGuids: [
      // If any ONE of these is set to a negative connotation, set the announcement
      '040809a1-a1bd-48ac-a886-5053e3f7172c', // Does the vehicle have any engine vibrations?
      '064d9347-e5ba-4847-8578-934f1a5c0812', // Does the vehicle have any engine smoke?
      '30d3523c-2054-420d-9855-59eaacf77f27' // Does the vehicle have any engine noise?
    ],
    connotationIsNegative: true
  },
  AN0019: {
    // Hail Damage
    questionGuids: [
      'edcfbf66-afb8-4ee2-97ec-e32bdc15e9b8' // Does the vehicle have hail damage?
    ],
    connotationIsNegative: true
  },
  AN0023: {
    // No Air Conditioning
    questionGuids: [
      '92d9dea4-e201-482e-965e-0f9b8ea898e2' // Vehicle climate control
    ],
    connotations: [-3]
  },
  AN0025: {
    // Not Actual Miles / INOP Odo
    questionGuids: [
      'afc967ab-8cc5-40de-bdcf-5a0deb61e495' // Dashboard or console damage?
    ],
    connotations: [-2]
  },
  AN0031: {
    // Prior Paint
    questionGuids: [
      '911ab2e1-2f4a-4d46-a837-d26898407a30' // Is there prior paintwork?
    ],
    connotationIsNegative: true
  },
  AN0034: {
    // SRS Missing / Airbag Problem
    questionGuids: [
      '9ff63319-3e13-4db1-8598-39c71394bdef' // Is the dashboard SRS light illuminated?
    ],
    connotations: [-2, -3]
  },
  AN0036: {
    // Structural Alteration
    questionGuids: [
      'b915f123-9c93-4f6d-8eeb-2ac45f46c98a' // Does this vehicle have any structural alterations?
    ],
    connotationIsNegative: true
  },
  AN0037: {
    // Frame or Structural Damage
    questionGuids: [
      'e8bf9827-b96c-40ff-9a03-e0e8aa6fbc08' // Is there any Frame or Structural damage?
    ],
    connotationIsNegative: true
  },
  AN0039: {
    // Transmission Problem
    questionGuids: [
      '40b64d84-c1d5-4caf-8a6c-92f4d81a72ff' // Does the vehicle have any transmission issues?
    ],
    connotationIsNegative: true
  }
};

const answerMatchesMapping = (answer: QEAnswer, mapping: QuestionAndAnnouncementMapping) => {
  if ('connotationIsNegative' in mapping) {
    return answer.connotation < 0;
  }
  return mapping.connotations.includes(answer.connotation);
};

// This only needs to happen to the last GFB questionnaire that does not have mappings
// built into the form.
// Once we start using Forms API to serve up questionnaires with built-in mappings, this
// function (and whole file) can be deleted.
export const injectMappingsIntoOldForm = (href: string, questions: QEQuestion[]) =>
  href.includes('039f4c41-c9eb-49df-83c2-9b8847219b05')
    ? questions.map(question =>
        Object.entries(MAPPINGS)
          .filter(([, mapping]) => mapping.questionGuids.includes(question.guid))
          .reduce<QEQuestion>(
            (q, [code, mapping]) => ({
              ...q,
              answers: q.answers.map(a =>
                answerMatchesMapping(a, mapping) ? { ...a, announcements: [...(a.announcements || []), code] } : a
              )
            }),
            question
          )
      )
    : questions;
