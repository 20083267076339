const prism = {
  manheimNavy: '#003468',
  manheimNavyDark: '#001b35',
  manheimPrimaryBlue: '#005ba8',
  manheimPrimaryBlueDark: '#004986',
  manheimLightBlue: '#c0dff3',
  manheimCeruleanLight: '#c1dff2',
  manheimCerulean: '#2c90cc',
  manheimCeruleanDark: '#2372a2',
  manheimGoldLight: '#fff0c3',
  manheimGold: '#ffc20e',
  manheimGoldDark: '#ebb000',
  manheimOcean: '#0074B7',
  manheimOceanOpacity: 'rgb(0,116,183, 0.4)',
  white: '#ffffff',
  ash: '#eeeeee',
  smoke: '#babcbe',
  slate: '#6a6a6a',
  slateOpacity: 'rgb(106,106,106, 0.4)',
  charcoal: '#4a4a4a',
  black: '#000000',
  scarletLight: '#fcccc0',
  scarlet: '#c33a00',
  scarletDark: '#902b00',
  meadowLight: '#e3fad1',
  meadow: '#82c250',
  jungle: '#0d8240',
  manheimSaffron: '#eb9114',
  manheimSaffronDark: '#c0650c',
  plum: '#8c1d58',
  ocean: '#00aaa8'
};
const defaultTheme = {
  pageWidth: '900px',
  breakpoints: {
    mobileSm: '320px',
    mobilelarge: '480px',
    fullWidth: '768px'
  },
  space: {
    xxs: '0.25em',
    xs: '0.5em',
    sm: '0.75em',
    md: '1em',
    lg: '1.5em',
    xl: '2em'
  },
  radius: {
    md: '3px',
    lg: '5px',
    xl: '10px'
  },
  color: {
    headerTextColor: prism.black,
    textColor: prism.black,
    primaryButtonBackground: prism.manheimGoldDark,
    primaryButtonBorder: prism.manheimGoldDark,
    primaryButtonText: prism.black,
    secondaryButtonBackground: prism.manheimNavy,
    secondaryButtonBorder: prism.manheimNavy,
    secondaryButtonText: prism.white,
    activeButtonBackground: prism.manheimNavy,
    activeButtonBorder: prism.black,
    activeButtonText: prism.white,
    inactiveButtonBackground: prism.white,
    inactiveButtonBorder: prism.black,
    inactiveButtonText: prism.black,
    nestedAnswerButtonText: prism.black,
    nestedAnswerButtonBackground: prism.manheimGoldLight,
    disabledButtonBorder: prism.black,
    disabledButtonBackground: prism.smoke,
    disabledButtonText: prism.slate,
    progressIndicator: prism.manheimNavy,
    checkboxColor: prism.manheimNavy,
    disabled: prism.smoke,
    activeContainerBackground: prism.manheimLightBlue,
    qtyPickerIconText: prism.manheimOcean,
    disabledQtyPickerIconText: prism.manheimOceanOpacity,
    enabledButtonBorder: prism.manheimOcean,
    inactiveIncrementButtonBorder: prism.slateOpacity
  },
  typography: {
    family: 'Roboto, sans-serif',
    size: {
      sm: '0.8em',
      md: '14px',
      lg: '1em',
      xl: '1.15em'
    }
  }
};

export type AppTheme = typeof defaultTheme;

export type ThemeColors = typeof defaultTheme['color'];

export const createTheme = (userColors: Partial<ThemeColors> | undefined) => ({
  ...defaultTheme,
  color: { ...defaultTheme.color, ...userColors }
});
