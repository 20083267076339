import styled from 'styled-components';

export const AnswerButton = styled.button`
  margin: 0 0 0 0; // Fixes safari automatic margin on buttons
  padding-top: ${({ theme }) => theme.space.md};
  padding-bottom: ${({ theme }) => theme.space.md};
  padding-right: ${({ theme }) => theme.space.xs};
  padding-left: ${({ theme }) => theme.space.xs};
  border-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; // Needed for Safari on iOS 14.0 bug
  font-size: ${({ theme }) => theme.typography.size.md};
  color: ${({ theme }) => theme.color.inactiveButtonText};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  border-color: ${({ theme }) => theme.color.inactiveButtonBorder};
  border-radius: 0;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.color.disabledButtonText};
    border-color: ${({ theme }) => theme.color.disabledButtonBorder};
    background-color: ${({ theme }) => theme.color.disabledButtonBackground};
  }

  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
    border-color: ${({ theme }) => theme.color.activeButtonBorder};
  }

  &.nested {
    margin-left: 26px;
  }
`;
