import styled from 'styled-components';
import React from 'react';

export const MAX_CHARACTERS = 50;

type Props = {
  label: string;
  value: string | undefined;
  onChange: (newValue: string) => void;
  disabled?: boolean;
};

export const AnswerNotes = ({ label, value, onChange, disabled }: Props) => {
  return (
    <NotesContainer>
      <NotesLabel>{label}</NotesLabel>
      <NotesInput
        data-test-id="notes-input"
        onChange={e => onChange(e.target.value)}
        defaultValue={value}
        maxLength={MAX_CHARACTERS}
        disabled={disabled}
      />
      <CharacterCount>{MAX_CHARACTERS - (value?.length || 0)} characters remaining</CharacterCount>
    </NotesContainer>
  );
};

const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.activeButtonText};
  background-color: ${({ theme }) => theme.color.activeButtonBackground};
  padding-left: ${({ theme }) => theme.space.xs};
  padding-right: ${({ theme }) => theme.space.xs};
`;

const NotesLabel = styled.label`
  display: flex;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.size.md};
  padding-top: ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.color.activeButtonText};
`;

const NotesInput = styled.input`
  display: flex;
  padding: ${({ theme }) => theme.space.xs};
  margin-top: ${({ theme }) => theme.space.xs};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.radius.md};
  color: ${({ theme }) => theme.color.textColor};
`;

const CharacterCount = styled.label`
  margin: ${({ theme }) => theme.space.xxs} 0;
  color: ${({ theme }) => theme.color.activeButtonText};
  font-size: ${({ theme }) => theme.typography.size.sm};
  display: flex;
`;
