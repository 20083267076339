import styled from 'styled-components';
import React from 'react';
import { Camera, Check } from '@styled-icons/fa-solid';
import { QEAnswer, QEQuestion } from '../../models/QEQuestion';
import { AlertMessageDetails } from '../../models/AppState';

type Props = {
  count: number;
  question: QEQuestion;
  answer: QEAnswer;
  sendAlertMessage?: (alertMessages: AlertMessageDetails) => void;
};

export const DamagesRecorded = ({ count, question, answer, sendAlertMessage }: Props) => {
  return (
    <DamagesRecordedContainer>
      <IconCompletedContainer data-test-id="icon--completed-container">
        <CheckMarkIcon />
        <CameraIcon />
      </IconCompletedContainer>
      <Count>{count}</Count>
      <DamagesRecordedLabel>{count === 1 ? 'Damage Recorded' : 'Damages Recorded'}</DamagesRecordedLabel>
      <EditButtonContainer>
        <EditButton
          onClick={() => {
            sendAlertMessage?.({
              questionGuid: question.guid,
              newAnswer: answer.value,
              selectedAnswers: [answer.value]
            });
          }}
        >
          Edit
        </EditButton>
      </EditButtonContainer>
    </DamagesRecordedContainer>
  );
};

const EditButtonContainer = styled.div`
  display: flex;
  outline: none;
  justify-content: center;
  background: transparent;
  box-shadow: none;
  align-items: center;
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
  color: ${({ theme }) => theme.color.secondaryButtonText};
`;

const EditButton = styled.div`
  height: 25px;
  border-left: 1px solid ${({ theme }) => theme.color.secondaryButtonText};
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
  color: ${({ theme }) => theme.color.secondaryButtonText};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  margin-left: 15px;
  margin-right: 15px;
  outline: none;
  }
`;

const Count = styled.label`
  font-weight: bold;
  font-size: 18px;
  margin-right: 15px;
`;

const DamagesRecordedLabel = styled.label`
  font-weight: bold;
`;

const DamagesRecordedContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
  padding-top: ${({ theme }) => theme.space.xs};
`;

const IconCompletedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
`;

const CheckMarkIcon = styled(Check)`
  margin-left: ${({ theme }) => theme.space.lg};
  padding-bottom: ${({ theme }) => theme.space.sm};
  height: 10px;
  width: 10px;
`;

const CameraIcon = styled(Camera)`
  height: 14px;
  width: 18px;
`;
