import React, { useState, useEffect } from 'react';
import { QuestionState } from '../../models/AppState';
import { Announcement } from '../../models/Announcement';
import { QEQuestion } from '../../models/QEQuestion';
import {
  findParents,
  questionAgreesWithAnnouncementOrIsUnstable,
  answersForQuestion,
  isAnnouncementSet,
  isQuestionStable,
  removeResponsesForOptionalQuestions,
  isQuestionRequired
} from '../../helpers/mappingHelpers';
import { Question } from '../QuestionsScene/Question';
import styled from 'styled-components';
import { Checkbox } from '../../components/Checkbox';
import { SecondaryButton, PrimaryButton } from '../../components/Buttons';
import { capitalize } from '../../helpers/capitalize';
import { HelpText } from '../../components/HelpText';
import { computeWorldState, WorldState } from '../../helpers/computeWorldState';

export const AnnouncementItem = ({
  announcement,
  username,
  client,
  onQuestionsChange,
  onAnnouncementChange,
  onPrompting,
  disabledBySource = false,
  readonly = false,
  announcementDictionary,
  worldState
}: {
  announcement: Announcement;
  username?: string;
  client: string;
  onQuestionsChange: (qResponses: QuestionState[]) => void;
  onAnnouncementChange: (ann: Announcement) => void;
  onPrompting: (ann: Announcement, prompting: boolean) => void;
  disabledBySource?: boolean;
  readonly?: boolean;
  announcementDictionary: Announcement[];
  worldState: WorldState;
}) => {
  const [draftQuestionResponses, setDraftQuestionResponses] = useState(worldState.questionResponses);
  const announcementState = worldState.announcements.find(ann => ann.code === announcement.code);
  const [announcementIsSet, setAnnouncementIsSet] = useState(
    isAnnouncementSet(announcement.code, worldState.announcements)
  );
  const withoutAnnouncement = worldState.announcements.filter(ann => ann.code !== announcement.code);
  const withAnnouncement = [
    ...worldState.announcements,
    { code: announcement.code, source: worldState.announcerSource, username }
  ];
  const inDraftMode = !!announcementState !== announcementIsSet;
  // If you want to render the Announcements scene and disable question prompting, send in an empty questionResponses
  // array and it disables all prompting. (useful when the disclosure is locked but you still want to update announcements)
  const doNotPrompt = worldState.questionResponses.length === 0;
  const filteredDraftQuestionResponses = removeResponsesForOptionalQuestions(
    draftQuestionResponses,
    worldState.questionnaire
  );

  const mappedQuestions = worldState.questionnaire.filter(q =>
    q.answers.some(a => a.announcements?.includes(announcement.code))
  );
  const areAnswersInAgreement = (simulatedResponses: QuestionState[], settingAnnouncement = announcementIsSet) => {
    if (!mappedQuestions.length) {
      return true;
    }
    const mappedQuestionsWithAgreements = mappedQuestions.map(question => ({
      question,
      agrees:
        // Must have answers to unset the announcement
        (!settingAnnouncement || answersForQuestion(question, simulatedResponses).length > 0) &&
        questionAgreesWithAnnouncementOrIsUnstable({
          guid: question.guid,
          code: announcement.code,
          announcements: settingAnnouncement ? withAnnouncement : withoutAnnouncement,
          questionResponses: simulatedResponses,
          questionnaire: worldState.questionnaire
        })
    }));
    return settingAnnouncement
      ? mappedQuestionsWithAgreements.some(({ agrees }) => agrees)
      : mappedQuestionsWithAgreements.every(({ agrees }) => agrees);
  };

  const answersAreInAgreement = areAnswersInAgreement(worldState.questionResponses);
  const draftAnswersAreInAgreement = areAnswersInAgreement(filteredDraftQuestionResponses);

  // If you think you need mappedQuestionsAndTheirParents, you probably need mappedQuestionsAndTheirParentsAndOtherRequiredQuestions
  const mappedQuestionsAndTheirParents = mappedQuestions.reduce<QEQuestion[]>((acc, question) => {
    if (!worldState.isQuestionRequired(question)) {
      // Add the parents to the list..
      const parents = findParents(question, worldState.questionnaire);
      return [...acc, ...parents, question];
    }
    return [...acc, question];
  }, []);

  const questionsToDisplay = worldState.questionnaire.filter(question => {
    // we only want to display a nestedQuestion if it maps directly to the announcement
    // otherwise it will render within it's parent question
    if (worldState.isNestedQuestion(question) && !mappedQuestions.includes(question)) {
      return false;
    }
    if (
      mappedQuestionsAndTheirParents.includes(question) ||
      !isQuestionStable({
        question,
        questionnaire: worldState.questionnaire,
        questionResponses: filteredDraftQuestionResponses
      })
    ) {
      return true;
    }
    const answers = answersForQuestion(question, worldState.questionResponses);
    const draftAnswers = answersForQuestion(question, draftQuestionResponses);
    const answersAreTheSame = answers.length === draftAnswers.length && answers.every(a => draftAnswers.includes(a));
    return !answersAreTheSame;
  });

  const exitDraftMode = () => {
    setAnnouncementIsSet(!!announcementState);
    setDraftQuestionResponses(worldState.questionResponses);
  };

  const allRequiredQuestionsAreAnswered = questionsToDisplay.every(question => {
    if (isQuestionRequired(question, filteredDraftQuestionResponses, worldState.questionnaire)) {
      const answers = answersForQuestion(question, filteredDraftQuestionResponses);
      const answered = answers.length > 0;
      return answered;
    }
    return true;
  });

  useEffect(() => exitDraftMode(), [announcementState, worldState.questionResponses]);

  const prompting = !readonly && !doNotPrompt && !answersAreInAgreement;
  useEffect(() => onPrompting(announcement, !answersAreInAgreement), [prompting]);
  const disabled = readonly || disabledBySource;

  const handleAnnouncementChange = () => {
    if (!disabled) {
      if (inDraftMode) {
        exitDraftMode();
        return;
      }

      const answersAreInAgreementWithAnnouncementChange = areAnswersInAgreement(
        worldState.questionResponses,
        !announcementIsSet
      );

      if (doNotPrompt || answersAreInAgreementWithAnnouncementChange) {
        onAnnouncementChange(announcement);
      } else {
        // This starts draft-mode
        setAnnouncementIsSet(!announcementIsSet);
      }
    }
  };

  const handleQuestionConfirm = () => {
    if (inDraftMode) {
      // Only fire when the prompt was triggered by a
      // proposed announcement change
      onAnnouncementChange(announcement);
    }
    onQuestionsChange(filteredDraftQuestionResponses);
  };

  const draftWorldState = computeWorldState({
    announcements: worldState.announcements,
    announcerSource: worldState.announcerSource,
    initialAnnouncements: worldState.initialAnnouncements,
    questionResponses: draftQuestionResponses,
    preservedResponses: worldState.preservedResponses,
    questionnaire: worldState.questionnaire,
    tags: worldState.tags,
    userType: worldState.userType
  });
  return (
    <Container>
      <AnnouncementContainer
        data-test-id="announcement-container"
        onClick={handleAnnouncementChange}
        disabled={disabled}
      >
        <AnnouncementText>{announcement.text}</AnnouncementText>
        <Checkbox checked={announcementIsSet} disabled={disabled} />
      </AnnouncementContainer>
      {announcementState && (
        <IdentifiedByText>{`Identified by ${capitalize(announcementState.source)}`}</IdentifiedByText>
      )}
      {prompting && (
        <>
          <HelpText>
            This announcement relates to a question. In order to continue, you must change the answer to the question(s)
            below.
          </HelpText>
          {questionsToDisplay.map(
            question =>
              isQuestionRequired(question, filteredDraftQuestionResponses, worldState.questionnaire) && (
                <Question
                  client={client}
                  key={question.guid}
                  onChange={setDraftQuestionResponses}
                  question={question}
                  showAnsweredIndicator={false}
                  announcementDictionary={announcementDictionary}
                  showHelpText={false}
                  worldState={draftWorldState}
                />
              )
          )}
          <ButtonContainer>
            <SecondaryButton onClick={exitDraftMode}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={!draftAnswersAreInAgreement || !allRequiredQuestionsAreAnswered}
              onClick={handleQuestionConfirm}
            >
              Confirm
            </PrimaryButton>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.xs};
  font-family: ${({ theme }) => theme.typography.family};
  font-size: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.color.disabled};
  &:last-child {
    border-bottom: unset;
  }
`;

const AnnouncementContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${props => (props.disabled ? props.theme.color.disabled : 'unset')};
`;

const IdentifiedByText = styled.label`
  color: ${({ theme }) => theme.color.disabledButtonText};
  padding-left: ${({ theme }) => theme.space.md};
`;

const AnnouncementText = styled.p``;
