import { QuestionState, TakePhotoDetails } from '../models/AppState';
import { QEAnswer, QEQuestion, TakePhotoType } from '../models/QEQuestion';
import { WorldState } from './computeWorldState';

export const getQuantity = (worldState: WorldState, question: QEQuestion, answer: QEAnswer) =>
  worldState.questionResponses.find(r => r.guid === question.guid)?.answers.find(a => a.value === answer.value)
    ?.quantity || 0;

export const getPhotoCaptured = (
  worldStateQuestionResponses: QuestionState[],
  question: QEQuestion,
  answer: QEAnswer
) =>
  worldStateQuestionResponses.find(r => r.guid === question.guid)?.answers.find(a => a.value === answer.value)
    ?.photoCaptured || false;

export const showPhotoButton = (
  worldState: WorldState,
  question: QEQuestion,
  answer: QEAnswer,
  inlineImaging?: boolean
) =>
  inlineImaging &&
  answer.metaData?.takePhoto?.type === TakePhotoType.Damage &&
  worldState.answersForQuestion(question).some(a => a.value === answer.value) &&
  !getPhotoCaptured(worldState.questionResponses, question, answer);

export const showDamagesReported = (
  worldState: WorldState,
  question: QEQuestion,
  answer: QEAnswer,
  inlineImaging?: boolean
) =>
  inlineImaging &&
  answer.metaData?.takePhoto?.type === TakePhotoType.Damage &&
  worldState.answersForQuestion(question).some(a => a.value === answer.value) &&
  getPhotoCaptured(worldState.questionResponses, question, answer);

export const handlePhotoButtonClick = (
  worldState: WorldState,
  question: QEQuestion,
  selectedAnswer: QEAnswer,
  onPhotoButtonClick?: (takePhotos: TakePhotoDetails) => void
) => {
  const existingResponse = worldState.questionResponses.find(r => r.guid === question.guid);
  const answerDetails = existingResponse?.answers.find(a => a.value === selectedAnswer.value);

  //Need to handle when photo is captured and user is clicking the button again
  if (answerDetails?.photoCaptured) {
    return;
  }

  onPhotoButtonClick?.({
    questionGuid: question.guid,
    answer: answerDetails?.value || '',
    quantity: answerDetails?.quantity,
    photoTitle: selectedAnswer.metaData?.takePhoto?.title,
    photoDescription: selectedAnswer.metaData?.takePhoto?.description
  });
};
