import axios from 'axios';
import { AppConfiguration } from '../models/AppConfiguration';
import { EnvironmentName } from '../models/EnvironmentName';

const URLS: Record<EnvironmentName, string> = {
  local: 'https://localhost:8080',
  integration1: 'https://int1-concierge-backend.awscafsnp.manheim.com/qnaproxy',
  integration5: 'https://int5-concierge-backend.awscafsnp.manheim.com/qnaproxy',
  uat: 'https://uat-concierge-backend.awscafs.manheim.com/qnaproxy',
  production: 'https://prod-concierge-backend.awscafs.manheim.com/qnaproxy'
};

const stripManheimDomains = (url: string) => {
  const matches = /^.*?\/\/(.*?)\//.exec(url);
  return matches && /api\.manheim\.com/.test(matches[0]) ? url.replace(/^.*?\/\/.*?\//, '/') : url;
};

const requestInterceptors: number[] = [];

const addRequestInterceptor = (...args: Parameters<typeof axios['interceptors']['request']['use']>) => {
  requestInterceptors.push(axios.interceptors.request.use(...args));
};

const clearInterceptors = () => {
  requestInterceptors.forEach(id => axios.interceptors.request.eject(id));
};

export const setupAxios = (config: AppConfiguration) => {
  const baseURL = URLS[config.environment] || URLS.integration1;
  clearInterceptors();
  addRequestInterceptor(requestConfig => ({
    ...requestConfig,
    url: requestConfig.url && stripManheimDomains(requestConfig.url),
    headers: {
      'Content-Type': 'application/json',
      ...requestConfig.headers
    },
    baseURL
  }));
};
