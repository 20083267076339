import styled from 'styled-components';

const Button = styled.button`
  border-radius: ${({ theme }) => theme.radius.lg};
  margin: ${({ theme }) => theme.space.xs};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: ${({ theme }) => theme.typography.size.md};
  border: 1px solid ${({ theme }) => theme.color.inactiveButtonBorder};
  background-color: ${({ theme }) => theme.color.inactiveButtonBackground};
  color: ${({ theme }) => theme.color.inactiveButtonText};
  line-height: 44px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.disabledButtonBackground};
    border-color: ${({ theme }) => theme.color.disabledButtonBorder};
    color: ${({ theme }) => theme.color.disabledButtonText};
  }

  &.selected {
    color: ${({ theme }) => theme.color.activeButtonText};
    border-color: ${({ theme }) => theme.color.activeButtonBorder};
    background-color: ${({ theme }) => theme.color.activeButtonBackground};
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.primaryButtonBackground};
  border: 1px solid ${({ theme }) => theme.color.primaryButtonBorder};
  color: ${({ theme }) => theme.color.primaryButtonText};
`;

export const SecondaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.secondaryButtonBackground};
  border: 1px solid ${({ theme }) => theme.color.secondaryButtonBorder};
  color: ${({ theme }) => theme.color.secondaryButtonText};
`;
