import axios from 'axios';

import { VehicleDescriptionType } from '../models/VehicleDesignatedDescription';

export const fetchVehicleDescription = async (vin: string): Promise<VehicleDescriptionType> => {
  const response = await axios.request<VehicleDescriptionType>({
    method: 'get',
    url: `/designated-description/decode/vin/${vin}`,
    headers: {
      'Application-Source': 'Tachi'
    }
  });

  return response.data;
};
